import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { dateAndTimeToDateTime } from "@/utils/DateTime";
import { ICheckOverlappingAppointmentsOptions } from "@/interfaces/ICheckOverlappingAppointmentsOptions";
import { ICheckWorkingHoursRepeatingEvent } from "@/interfaces/ICheckOverlappingAppointmentsRepeatingEventOptions";
import { ICheckOverlappingAppointmentsOptionsVehicle } from "@/interfaces/ICheckOverlappingAppointmentsOptionsVehicle";
import { ICheckOverlappingAppointmentsRepeatingEventOptionsVehicle } from "@/interfaces/ICheckOverlappingAppointmentsRepeatingEventOptionsVehicle";

const CalendarModule = namespace("calendar");

@Component
export default class CheckWorkingHoursAndOverlappingAppointmentMixin extends Vue {
  /**
   * Check instructor working hours store
   */
  @CalendarModule.Action("checkInstructorWorkingHours")
  public checkInstructorWorkingHours!: (options: ICheckOverlappingAppointmentsOptions) => Promise<void>;

  @CalendarModule.Getter("getInstructorCheckWorkingHours")
  public getInstructorCheckWorkingHours!: boolean | null;

  @CalendarModule.Action("checkInstructorWorkingHoursRepeatingEvent")
  public checkInstructorWorkingHoursRepeatingEvent!: (options: ICheckWorkingHoursRepeatingEvent) => Promise<void>;

  @CalendarModule.Getter("getInstructorCheckWorkingHoursRepeatingEvent")
  public getInstructorCheckWorkingHoursRepeatingEvent!: boolean | null;

  /**
   * Check instructor appointment overlapping store
   */
  @CalendarModule.Action("checkInstructorOverlappingAppointments")
  public checkInstructorOverlappingAppointments!: (options: ICheckOverlappingAppointmentsOptions) => Promise<void>;

  @CalendarModule.Getter("getInstructorCheckOverlappingAppointments")
  public getInstructorCheckOverlappingAppointments!: boolean | null;

  @CalendarModule.Action("checkInstructorOverlappingAppointmentsRepeatingEvent")
  public checkInstructorOverlappingAppointmentsRepeatingEvent!: (options: ICheckWorkingHoursRepeatingEvent) => Promise<void>;

  @CalendarModule.Getter("getInstructorCheckOverlappingAppointmentsRepeatingEvent")
  public getInstructorCheckOverlappingAppointmentsRepeatingEvent!: boolean | null;

  /**
   * Check vehicle appointment overlapping store
   */
  @CalendarModule.Action("checkVehicleOverlappingAppointments")
  public checkVehicleOverlappingAppointments!: (options: ICheckOverlappingAppointmentsOptionsVehicle) => Promise<void>;

  @CalendarModule.Getter("getVehicleCheckOverlappingAppointments")
  public getVehicleCheckOverlappingAppointments!: boolean | null;

  @CalendarModule.Action("checkVehicleOverlappingAppointmentsRepeatingEvent")
  public checkVehicleOverlappingAppointmentsRepeatingEvent!: (options: ICheckOverlappingAppointmentsRepeatingEventOptionsVehicle) => Promise<void>;

  @CalendarModule.Getter("getVehicleCheckOverlappingAppointmentsRepeatingEvent")
  public getVehicleCheckOverlappingAppointmentsRepeatingEvent!: boolean | null;

  @CalendarModule.Action("checkVehicleOverlappingAppointmentsAsArray")
  public checkVehicleOverlappingAppointmentsAsArray!: (options: ICheckOverlappingAppointmentsOptionsVehicle) => Promise<void>;

  @CalendarModule.Getter("getVehicleCheckOverlappingAppointmentsAsArray")
  public getVehicleCheckOverlappingAppointmentsAsArray!: boolean | null;

  /**
   * Working hours modal
   */
  protected workingHoursModalId = "check-working-hours-modal";
  protected checkHoursModalConfirmed = false;

  /**
   * Overlapping appointments modal
   */
  protected overlappingAppointmentsModalId = "check-overlapping-appointments-modal";
  protected checkOverlappingAppointmentModalConfirmed = false;

  /**
   * Overlapping appointments vehicle modal
   */
  protected overlappingAppointmentsVehicleModalId = "check-overlapping-appointments-vehicle-modal";
  protected checkOverlappingAppointmentVehicleModalConfirmed = false;

  /**
   * Overlapping appointments vehicle trailer modal
   */
  protected overlappingAppointmentsVehicleTrailerModalId = "check-overlapping-appointments-vehicle-trailer-modal";
  protected checkOverlappingAppointmentVehicleTrailerModalConfirmed = false;

  /**
   * Overlapping appointments vehicle escort modal
   */
  protected overlappingAppointmentsVehicleEscortModalId = "check-overlapping-appointments-vehicle-escort-modal";
  protected checkOverlappingAppointmentVehicleEscortModalConfirmed = false;

  protected dropActionIsConfirmed = false;

  /**
   * Check instructor working hours
   */
  protected async checkInstructorWorkingHoursFn(data: ICheckOverlappingAppointmentsOptions): Promise<number> {
    await this.checkInstructorWorkingHours(data);

    this.hasErrorThrow(this.getInstructorCheckWorkingHours);

    if (this.getInstructorCheckWorkingHours === false) {
      this.$bvModal.show(this.workingHoursModalId);
      return 1;
    }
    return 0;
  }

  /**
   * Check instructor overlapping appointments
   */
  protected async checkInstructorOverlappingAppointmentsFn(data: ICheckOverlappingAppointmentsOptions): Promise<number> {
    await this.checkInstructorOverlappingAppointments(data);

    this.hasErrorThrow(this.getInstructorCheckOverlappingAppointments);

    if (this.getInstructorCheckOverlappingAppointments === false) {
      this.$bvModal.show(this.overlappingAppointmentsModalId);
      return 1;
    }
    return 0;
  }

  /**
   * Check vehicle overlapping appointments
   */
  protected async checkVehicleOverlappingAppointmentsFn(
    data: ICheckOverlappingAppointmentsOptionsVehicle,
    modalId = this.overlappingAppointmentsVehicleModalId
  ): Promise<boolean> {
    await this.checkVehicleOverlappingAppointments(data);

    this.hasErrorThrow(this.getVehicleCheckOverlappingAppointments);

    if (this.getVehicleCheckOverlappingAppointments === false) {
      this.$bvModal.show(modalId);
      return true;
    }
    return false;
  }

  protected async checkVehicleOverlappingAppointmentArrayFn(
    data: ICheckOverlappingAppointmentsOptionsVehicle,
    modalId = this.overlappingAppointmentsVehicleModalId
  ): Promise<boolean> {
    await this.checkVehicleOverlappingAppointmentsAsArray(data);

    this.hasErrorThrow(this.getVehicleCheckOverlappingAppointmentsAsArray);

    if (this.getVehicleCheckOverlappingAppointmentsAsArray === false) {
      this.$bvModal.show(modalId);
      return true;
    }

    return false;
  }

  /**
   * Helpers
   */
  protected hasErrorThrow(data: boolean | null): void {
    if (data === null) {
      throw "Server error!";
    }
  }
  protected toDateTime(date: string, time: string): string {
    return dateAndTimeToDateTime(date, time);
  }
  protected resetConfirmationState(): void {
    this.checkHoursModalConfirmed = false;
    this.checkOverlappingAppointmentModalConfirmed = false;
    this.checkOverlappingAppointmentVehicleModalConfirmed = false;
    this.checkOverlappingAppointmentVehicleTrailerModalConfirmed = false;
    this.checkOverlappingAppointmentVehicleEscortModalConfirmed = false;
    this.dropActionIsConfirmed = false;
    this.$bvModal.hide(this.workingHoursModalId);
  }

  /**
   * Check instructor repeat event working hours
   */
  protected async checkInstructorWorkingHoursRepeatEventFn(data: ICheckWorkingHoursRepeatingEvent): Promise<number> {
    await this.checkInstructorWorkingHoursRepeatingEvent(data);

    this.hasErrorThrow(this.getInstructorCheckWorkingHoursRepeatingEvent);

    if (this.getInstructorCheckWorkingHoursRepeatingEvent === false) {
      this.$bvModal.show(this.workingHoursModalId);
      return 1;
    }

    return 0;
  }

  /**
   * Check instructor repeat event overlapping appointments
   */
  protected async checkInstructorOverlappingAppointmentsRepeatEventFn(data: ICheckWorkingHoursRepeatingEvent): Promise<number> {
    await this.checkInstructorOverlappingAppointmentsRepeatingEvent(data);

    this.hasErrorThrow(this.getInstructorCheckOverlappingAppointmentsRepeatingEvent);

    if (this.getInstructorCheckOverlappingAppointmentsRepeatingEvent === false) {
      this.$bvModal.show(this.overlappingAppointmentsModalId);
      return 1;
    }
    return 0;
  }

  /**
   * Check vehicle repeat event overlapping appointments
   */
  protected async checkVehicleOverlappingAppointmentsRepeatEventFn(
    data: ICheckOverlappingAppointmentsRepeatingEventOptionsVehicle,
    modalId = this.overlappingAppointmentsVehicleModalId
  ): Promise<boolean> {
    await this.checkVehicleOverlappingAppointmentsRepeatingEvent(data);

    this.hasErrorThrow(this.getVehicleCheckOverlappingAppointmentsRepeatingEvent);

    if (this.getVehicleCheckOverlappingAppointmentsRepeatingEvent === false) {
      this.$bvModal.show(modalId);
      return true;
    }
    return false;
  }
}
