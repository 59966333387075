









import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class BookButton extends Vue {
  public name = "BookButton";

  @Prop({ default: () => "Buchen", type: String })
  public label!: string;
}
