




















































































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import Datepicker from "@/components/Datepicker.vue";
import TimePicker from "@/components/TimePicker.vue";
import FscTable from "@/components/Table.vue";
import { getFile } from "@/utils/File";
import { mixins } from "vue-class-component";
import TheoryMixin from "@/mixins/TheoryMixin";
import Validation from "@/components/Validation.vue";
import TheoryAttendanceDocumentPreview from "@/views/Theory/TheoryAttendanceDocumentPreview.vue";
import FscMultiselect from "@/components/FscMultiselect.vue";

@Component({
  components: {
    FscTable,
    FscMultiselect,
    TimePicker,
    Datepicker,
    SaveButton,
    AbortButton,
    TheoryAttendanceDocumentPreview,
    Validation,
  },
})
export default class TheorySessions extends mixins(TheoryMixin) {
  public name = "TheorySessions";

  protected theorySessionFields = [
    {
      key: "date",
      label: this.$t("calendar.form_date"),
      sortable: false,
    },
    {
      key: "time",
      label: this.$t("calendar.form_time"),
      sortable: false,
    },
    {
      key: "description",
      label: this.$t("roles.description"),
      sortable: false,
    },
    {
      key: "instructor.initials",
      label: this.$t("general.fl"),
      sortable: false,
    },
    {
      key: "control",
      label: this.$t("general.control"),
      sortable: false,
      class: "text-center",
    },
    {
      key: "webinar",
      label: this.$t("general.webinar"),
      sortable: false,
      class: "text-center",
    },
    {
      key: "info",
      label: this.$t("general.extension"),
      sortable: false,
      class: "text-center",
    },
    {
      key: "studentCounts",
      label: this.$t("general.student_counts"),
      sortable: false,
      class: "text-center",
    },
  ];

  @Prop({ type: Boolean, default: () => false })
  protected isLoading!: any;

  @Prop({ type: Array, default: () => [] })
  protected theoryLessons!: any;

  @Prop({ type: Function, default: () => null })
  protected onSelectedRow!: any;

  @Prop({ type: Function, default: () => null })
  protected onAbort!: any;

  @Prop({ type: Function, default: () => null })
  protected onSubmit!: any;

  @Prop({ type: Array, default: () => [] })
  protected theoryCourseOptions!: any;

  @Prop({ type: Array, default: () => [] })
  protected knowledgeAreas!: any;

  @Prop({ type: Array, default: () => [] })
  protected furhterEducationKnowledgeAreas!: any;

  @Prop({ type: Array, default: () => [] })
  protected instructors!: any;

  @Prop({ type: Boolean, default: () => false })
  protected isCourse!: any;

  @Prop({ type: Number, default: () => 0 })
  protected courseId: any;

  @Prop()
  protected selectedLessonTheoryClass: any;

  @Prop()
  protected v!: any;

  public pdfDocumentId: any = null;
  public knowledgeAreasOptions = [];

  protected getKnowledgeAreas(item: any): string {
    return item.knowledgeAreas.map((x: any) => x.name).join(" ");
  }

  protected getTheoryClass(item: any): string {
    return item.theoryClass?.name;
  }

  protected getDocument(id: number): void {
    if (!id) return;
    getFile({
      method: "GET",
      url: `/theory-lesson-documents/${id}`,
    });
  }

  protected getTime(item: any): string {
    return `${item.time} - ${item.endTime}`;
  }

  protected getClickableLink(link: string): string {
    return link.startsWith("http://") || link.startsWith("https://") ? link : `http://${link}`;
  }

  protected submitForm(item: any): void {
    this.v.$touch();
    if (this.v.$invalid) return;
    if (item.isNew) {
      if (!item.forGrantingTypeExtension) {
        Object.assign(item, { forGrantingTypeExtension: false });
      }
      Object.assign(item, {
        course: { id: this.courseId },
      });
    }
    this.onSubmit(item);
  }

  public pdfSignatureDocumentPreview(item: any): void {
    this.pdfDocumentId = item.theoryLessonDocumentId;
    this.$bvModal.show("attendanceDocumentPopover");
  }

  protected onTheoryClassChange(selectedOptions: any) {
    this.$emit("on-class-change", selectedOptions);
  }

  @Watch("selectedLessonTheoryClass", { deep: true })
  public onSelectedLessonTheoryClassChange() {
    if (!this.selectedLessonTheoryClass?.name) {
      this.knowledgeAreasOptions = [];
    } else {
      if (this.selectedLessonTheoryClass?.furtherEducation) {
        this.knowledgeAreasOptions = this.furhterEducationKnowledgeAreas;
      } else {
        this.knowledgeAreasOptions = this.knowledgeAreas;
      }
    }
  }

  protected hasSignature(item: any): boolean {
    return item.studentTheoryLessons.some(function (e: any) {
      return e.signed === true;
    });
  }

  protected onClickSignature(item: any): void {
    this.$emit("on-click-signature", item);
  }
}
