




















import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class CustomSlider extends Vue {
  public name = "CustomSlider";
  public arrows: any = {
    hasNext: true,
    hasPrev: false,
  };

  public arrowLeftIsClicked = false;
  public arrowRightIsClicked = false;

  public mounted() {
    this.arrowsShowInit();
    window.addEventListener("resize", () => {
      this.arrowsShowInit();
    });
  }

  public updated() {
    if (this.arrowLeftIsClicked || this.arrowRightIsClicked) {
      this.updateArrowsPresence();
    } else {
      this.arrowsShowInit();
    }
  }

  public get hasNext() {
    return this.arrows.hasNext;
  }

  public get hasPrev() {
    return this.arrows.hasPrev;
  }

  public arrowsShowInit(): void {
    this.$nextTick(() => {
      const slides = this.$refs.slides as HTMLElement;
      const container = this.$refs.sliderContainer as HTMLElement;
      if (slides && container) {
        const slidesLeft = Math.round(slides.getBoundingClientRect().left);
        const containerLeft = Math.round(container.getBoundingClientRect().left);
        const slidesRight = Math.round(slides.getBoundingClientRect().right);
        const containerRight = Math.round(container.getBoundingClientRect().right);
        this.arrows.hasPrev = containerLeft - slidesLeft > 0;
        this.arrows.hasNext = slidesRight - containerRight > 0;
      }
    });
  }

  public calcShowNextPrev(type: string): void {
    const slides = this.$refs.slides as HTMLElement;
    const container = this.$refs.sliderContainer as HTMLElement;
    if (slides && container) {
      switch (type) {
        case "prev": {
          const slidesLeft = Math.round(slides.getBoundingClientRect().left);
          const containerLeft = Math.round(container.getBoundingClientRect().left);
          const containerWidth = Math.round(container.getBoundingClientRect().width);

          this.arrows.hasPrev = !(containerLeft - slidesLeft < containerWidth) || this.arrowRightIsClicked;
          break;
        }
        case "next": {
          const slidesRight = Math.round(slides.getBoundingClientRect().right);
          const containerRight = Math.round(container.getBoundingClientRect().right);
          const containerWidth = Math.round(container.getBoundingClientRect().width);

          this.arrows.hasNext = slidesRight - containerRight > containerWidth || this.arrowLeftIsClicked;
          break;
        }
      }
    }
  }

  public updateArrowsPresence() {
    this.$nextTick(() => {
      this.calcShowNextPrev("prev");
      this.calcShowNextPrev("next");
    });
  }

  public counter = 0;

  public prevHandle() {
    let step = 0;
    if (this.counter >= 1) {
      this.counter -= 1;
    }
    if (this.$refs.slides && this.$refs.sliderContainer) {
      const container = {
        width: Math.round((this.$refs.sliderContainer as HTMLElement).getBoundingClientRect().width),
        left: Math.round((this.$refs.sliderContainer as HTMLElement).getBoundingClientRect().left),
      };
      const slides = {
        left: Math.round((this.$refs.slides as HTMLElement).getBoundingClientRect().left),
      };

      if (slides.left >= container.left) {
        this.arrowRightIsClicked = false;
        this.updateArrowsPresence();
        return;
      }
      const offsetLeft = Math.abs(container.left - slides.left);
      if (offsetLeft > container.width) {
        step = container.width - offsetLeft;
      } else {
        step = 0;
        this.arrowRightIsClicked = false;
      }
      (this.$refs.slides as HTMLElement).style["transform"] = `translateX(${step}px)`;
      this.arrowLeftIsClicked = true;
      this.updateArrowsPresence();
    }
  }

  public nextHandle() {
    let step = 0;

    if (this.$refs.slides && this.$refs.sliderContainer) {
      const container = {
        width: Math.round((this.$refs.sliderContainer as HTMLElement).getBoundingClientRect().width),
        right: Math.round((this.$refs.sliderContainer as HTMLElement).getBoundingClientRect().right),
      };
      const slides = {
        right: Math.round((this.$refs.slides as HTMLElement).getBoundingClientRect().right),
        width: Math.round((this.$refs.slides as HTMLElement).getBoundingClientRect().width),
      };
      const offsetRight = slides.right - container.right;

      if (slides.right - container.right <= 0) {
        this.counter = 0;
        this.arrowLeftIsClicked = false;
        this.updateArrowsPresence();
        return;
      }

      if (offsetRight - container.width >= container.width * this.counter) {
        this.counter += 1;
        step = this.counter * container.width;
      } else {
        step = offsetRight + this.counter * container.width;
        this.counter = 0;
        this.arrowLeftIsClicked = false;
      }
      step = -Math.abs(step);
      (this.$refs.slides as HTMLElement).style["transform"] = `translateX(${step}px)`;
      this.arrowRightIsClicked = true;
      this.updateArrowsPresence();
    }
  }
}
