






import { Component, Vue } from "vue-property-decorator";

@Component
export default class Actions extends Vue {
  public name = "Actions";
}
