




import { Component, Prop, Vue } from "vue-property-decorator";
import ButtonMixin from "@/mixins/ButtonMixin";
import { mixins } from "vue-class-component";
import { v4 as uuidv4 } from "uuid";
import ActionButton from "@/components/Button/ActionButton.vue";
@Component({
  components: { ActionButton },
})
export default class EditButton extends mixins(ButtonMixin) {
  @Prop({ default: () => uuidv4() })
  public id!: string;

  @Prop({ default: () => false, type: Boolean })
  public disabled!: boolean;

  public name = "EditButton";
}
