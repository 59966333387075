

































import { Component, Prop, Watch } from "vue-property-decorator";
import Datepicker from "@/components/Datepicker.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import { namespace } from "vuex-class";
import TheoryModuleBookMixin from "@/mixins/Request/TheoryModuleBookMixin";
import { mixins } from "vue-class-component";
import FscModal from "@/components/Modal/FscModal.vue";

const TheoryCourseModule = namespace("theory-course");
@Component({
  components: { SaveButton, AbortButton, Datepicker, FscModal },
})
export default class TheoryModuleBookModal extends mixins(TheoryModuleBookMixin) {
  public name = "TheoryModuleBookModal";

  @Prop()
  public theory!: any;

  @Prop({ default: false })
  public showModal!: any;

  @Prop({ type: Boolean })
  public isTheoryLesson!: boolean;

  @TheoryCourseModule.Action("bookTheoryCourseModules")
  private bookTheoryCourseAction: any;

  protected date: any | Date | null = "";

  public onAbort() {
    this.date = "";
    this.$bvModal.hide("moduleBookingModal");
    this.$emit("on-close");
  }

  public async onModuleBookSubmit() {
    const data = new Date(this.date);

    if (this.theory && !this.isTheoryLesson) {
      await this.bookTheoryCourseAction({ id: this.theory.id, data });
      this.date = "";
      this.$bvModal.hide("moduleBookingModal");
      this.$emit("on-close");
    } else if (this.theory?.id) {
      await this.theoryModuleBook(this.theory.id, data);
      if (this.theoryModuleBookSuccess) {
        this.date = "";
        this.$bvModal.hide("moduleBookingModal");
        this.$emit("on-close");
      }
    }
  }

  @Watch("showModal", { immediate: true, deep: true })
  public onShowModal(show: any) {
    if (show) {
      this.$bvModal.show("moduleBookingModal");
    }
  }

  public get isLoading() {
    return this.theoryModuleBookLoading;
  }
}
