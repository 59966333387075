
























import { Component, Prop, Vue } from "vue-property-decorator";
import SaveButton from "@/components/Button/SaveButton.vue";
import AbortButton from "@/components/Button/AbortButton.vue";

@Component({
  components: {
    SaveButton,
    AbortButton,
  },
})
export default class TheoryStudentMatchModal extends Vue {
  public name = "TheoryStudentMatchModal";

  public onAccept(): void {
    this.$emit("on-accept");
  }

  public onDecline(): void {
    this.$emit("on-decline");
  }
}
