import i18n from "@/i18n";

export const DRIVING_LESSON = 1;
export const THEORY_LESSON = 2;
export const PRIVATE = 3;
export const OTHER = 4;
export const PRACTICAL_EXAM = 5;
export const GQC_OR_GQD = 9;
export const THEORY_EXAM = 10;
export const DRIVING_SCHOOL_APPOINTMENT = 11;

export const APPOINTMENTS_TYPE = [
  {
    id: DRIVING_LESSON,
    name: i18n.t("calendar.driving_lesson"),
    color: "#384152",
  },
  {
    id: PRACTICAL_EXAM,
    name: i18n.t("calendar.practical_exam"),
    color: "#384152",
  },

  // {
  //   id: THEORY_LESSON,
  //   name: i18n.t("calendar.theory_lesson"),
  //   color: "bg-trinidad-500",
  // },
  {
    id: PRIVATE,
    name: i18n.t("calendar.private"),
    color: "#959A89",
  },
  {
    id: GQC_OR_GQD,
    name: i18n.t("calendar.driving_lesson"),
    color: "#384152",
  },
  {
    id: OTHER,
    name: i18n.tc("calendar.other", 1),
    color: "#8C5B68",
  },
];
