









import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ButtonMixin from "@/mixins/ButtonMixin";

@Component
export default class SendMailButton extends mixins(ButtonMixin) {
  public name = "SendMailButton";

  @Prop({ type: Boolean, default: false })
  public disabled?: any;

  @Prop({ type: String, default: "paper-plane" })
  public iconName?: any;
}
