















































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { $axios } from "@/utils/axios";
import { AxiosResponse } from "axios";
import moment from "moment/moment";
import { getFile } from "@/utils/File";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import Datepicker from "@/components/Datepicker.vue";
import FscModal from "@/components/Modal/FscModal.vue";
import { namespace } from "vuex-class";

const TheoryLessonModule = namespace("theory-lesson");

@Component({
  components: {
    AbortButton,
    SaveButton,
    Datepicker,
    FscModal,
  },
})
export default class TheoryPrintActionModal extends Vue {
  public name = "TheoryPrintActionModal";

  @Prop()
  public theory!: any;

  @Prop({ type: Boolean })
  public isTheoryLesson!: boolean;

  @Prop({ default: false })
  public showModal!: any;

  @TheoryLessonModule.Getter("getCourseDataList")
  public getCurrentCourse!: any;

  protected documentContentLoading = false;
  public radioSelected = "bkf-certificate";
  public printDocumentModal = "printDocumentModal";
  public documentDate: any | null = "";
  protected printDownloadDates: Array<any> = [];
  protected selectedPrintDownloadDates: Array<any> | any = [];
  protected bkfRegistrationTheoryLessonDates: Array<any> = [];
  protected certificateOptions: Array<any> = [
    {
      id: 508,
      type: "BKF_CERTIFICATE",
      value: "bkf-certificate",
      text: "Weiterbildung BKF",
    },
    {
      id: 0,
      type: "BQR_REGISTRATION_LIST",
      value: "bkf-registration-list",
      text: "Meldeliste BQR",
    },
  ];

  public datesLoading = false;

  protected get initialDate() {
    let date = moment();
    date = this.isTheoryLesson ? moment(this.theory.date) : moment(this.theory.startDate);
    return date.format("YYYY-MM-DD");
  }

  private theoryClassIsBKF(theoryClass: { name: string; id: number; furtherEducation: boolean }) {
    return theoryClass.name.toLowerCase().indexOf("wbc") > -1 || theoryClass.name.toLowerCase().indexOf("wbd") > -1;
  }

  protected getTheoryLessonsDatesByTheoryCourse() {
    if (this.getCurrentCourse && this.getCurrentCourse.length > 0) {
      this.bkfRegistrationTheoryLessonDates = this.getCurrentCourse.map((lesson: any) => ({
        date: lesson.date,
        id: lesson.id,
      }));
    }
  }

  public onRadioSelectionChange() {
    this.selectedPrintDownloadDates = [];
  }

  protected showDocumentSelectionForm() {
    if (this.theory && !this.isTheoryLesson) {
      const courseBKF = this.theory?.theoryClasses.find((theoryClass: any) => this.theoryClassIsBKF(theoryClass));
      if (courseBKF) {
        this.getTheoryLessonsDatesByTheoryCourse();
        this.documentDate = this.initialDate;
        this.$bvModal.show(this.printDocumentModal);
        this.loadDatesCertificate();
      } else {
        this.$toasted.info(this.$t("messages.function_supported_for_wbc_wbd").toString());
      }
    } else if (this.theory) {
      if (this.theoryClassIsBKF(this.theory.theoryClass)) {
        this.documentDate = this.initialDate;
        this.$bvModal.show(this.printDocumentModal);
        this.loadDatesCertificate();
      } else {
        this.$toasted.info(this.$t("messages.function_supported_for_wbc_wbd").toString());
      }
    }
  }

  protected async loadDatesCertificate(): Promise<any> {
    this.selectedPrintDownloadDates = [];
    const id = this.theory.id;

    if (!id) return;

    let url = `bkf-certificate/theory-course/get-date-options/${id}`;

    if (this.isTheoryLesson) {
      url = `bkf-certificate/theory-lesson/get-date-options/${id}`;
    }

    this.datesLoading = true;
    return await $axios
      .get(url)
      .then(({ data }: AxiosResponse) => {
        this.documentDate = this.initialDate;
        this.printDownloadDates = data;
        this.datesLoading = false;
      })
      .catch((e) => {
        this.printDownloadDates = [];
        this.datesLoading = false;
      });
  }

  protected onAbortRadioSelect() {
    this.$bvModal.hide(this.printDocumentModal);
    this.selectedPrintDownloadDates = [];
    this.$emit("on-close");
  }

  public onRadioSelectSubmit() {
    if (!this.radioSelected) return;
    switch (this.radioSelected) {
      case "bkf-certificate":
        this.onOpenPreview(this["onBKFCertificatePreview"]);
        break;
      case "bkf-registration-list":
        this.onOpenPreview(this["onBQRPreview"]);
        break;
    }
  }

  protected onOpenPreview(type: any): any {
    if (typeof type !== "function") return;
    this.documentContentLoading = true;
    type()
      .then((res: any) => {
        this.documentContentLoading = false;
        this.$bvModal.hide(this.printDocumentModal);
        this.$emit("on-close");
      })
      .catch((er: any) => {
        this.documentContentLoading = false;
        this.$bvModal.hide(this.printDocumentModal);
        this.$emit("on-close");
      });

    this.documentDate = "";
  }

  protected async onBKFCertificatePreview(): Promise<void> {
    const id = this.theory.id;

    if (id) {
      let url = `bkf-certificate/theory-course/${id}`;

      if (this.isTheoryLesson) {
        url = `/bkf-certificate/theory-lesson/${id}`;
      }

      return await getFile({
        method: "POST",
        url: url,
        data: {
          dates: this.selectedPrintDownloadDates,
          issueDate: this.documentDate ? this.documentDate : "",
        },
      })
        .then(() => {
          this.selectedPrintDownloadDates = [];
        })
        .catch(() => {
          this.selectedPrintDownloadDates = [];
        });
    } else {
      this.onAbortRadioSelect();
    }
  }

  protected async onBQRPreview(): Promise<void> {
    const id = this.theory.id;

    if (id) {
      let url = "";
      if (!this.isTheoryLesson) {
        const theoryLessonId = this.selectedPrintDownloadDates;
        url = `bkf-certificate/theory-lesson/${theoryLessonId}/attendance-list`;
      }
      if (this.isTheoryLesson) {
        url = `bkf-certificate/theory-lesson/${id}/attendance-list`;
      }

      return await getFile({
        method: "POST",
        url: url,
        data: {
          issueDate: this.documentDate ? this.documentDate : "",
        },
      })
        .then(() => {
          //
        })
        .catch(() => {
          //
        });
    } else {
      this.onAbortRadioSelect();
    }
  }

  @Watch("showModal", { immediate: true, deep: true })
  public onShowModal(show: any) {
    if (show) {
      this.showDocumentSelectionForm();
    }
  }
}
