










import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const TheoryDocumentsModule = namespace("theory-documents");

@Component
export default class TheoryAttendanceDocumentPreview extends Vue {
  public name = "TheoryAttendanceDocumentPreview";

  @TheoryDocumentsModule.Action("findAttendanceDocument")
  public findAttendanceDocument: any;

  @TheoryDocumentsModule.Getter("getPdfMimeType")
  public pdfMimeType: any;

  @TheoryDocumentsModule.Getter("getDataItem")
  public blob: any;

  @TheoryDocumentsModule.Getter("getIsLoading")
  public attendanceDocumentIsLoading: any;

  @Prop()
  documentId!: any;

  private source = "";

  public created(): void {
    this.findAttendanceDocument(this.documentId);
  }

  @Watch("blob")
  public onAtteendanceDocumentChange(blob: any) {
    if (blob) {
      this.source =
        "data:" +
        this.pdfMimeType +
        ";base64," +
        //@ts-ignore
        new Buffer(blob, "binary").toString("base64");
    }
  }
  @Watch("documentId")
  public onAttendanceDocumentIdChange(documentId: any) {
    if (documentId) {
      this.findAttendanceDocument(this.documentId);
    }
  }
}
