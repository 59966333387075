








import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SelectableDaysOfWeek extends Vue {
  public name = "SelectableDaysOfWeek";

  @Prop({ type: Array, default: () => [] })
  public value!: Array<number>;

  protected days = [
    this.$t("short_days.monday"),
    this.$t("short_days.tuesday"),
    this.$t("short_days.wednesday"),
    this.$t("short_days.thursday"),
    this.$t("short_days.friday"),
    this.$t("short_days.saturday"),
    this.$t("short_days.sunday"),
  ];

  protected onSelect(day: number): void {
    this.updateSelection(day);
  }

  protected updateSelection(resource: any) {
    if (this.value.indexOf(resource) < 0) return this.value.push(resource);
    const index = this.value.indexOf(resource);
    if (index > -1) return this.value.splice(index, 1);
  }

  protected isSelected(day: number): boolean {
    return this.value.indexOf(day) > -1;
  }
}
