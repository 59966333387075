import moment from "moment";

export const durationToTime = (time: string, duration: number): string => {
  return moment(time, "HH:mm:ss").add(duration, "minutes").format("HH:mm");
};

export const dateAndTimeToDateTime = (date: string, time: string): string => {
  return moment(`${date} ${time}`).format("YYYY-MM-DDTHH:mm:ss");
};

export const toDateTime = (dateTime: string | null): string => {
  if (!dateTime) return "";
  return moment(dateTime).format("DD.MM.YYYY HH:mm");
};

export const isBirthDay = (dateTime: string | null): boolean => {
  if (!dateTime) return false;
  const today = moment(new Date()).format("MM-DD");
  const birthDate = moment(dateTime).format("MM-DD");
  if (today === birthDate) {
    return true;
  }
  return false;
};
