

















































import { Component, Prop, Watch } from "vue-property-decorator";
import VerticalDivider from "@/components/VerticalDivider.vue";
import { mixins } from "vue-class-component";
import TheoryMixin from "@/mixins/TheoryMixin";
import _ from "lodash";
import CustomSlider from "@/components/Slider/CustomSlider.vue";

@Component({
  components: { VerticalDivider, CustomSlider },
})
export default class TheoryList extends mixins(TheoryMixin) {
  public name = "TheoryList";

  @Prop({ type: Array, default: () => [] })
  public items!: Array<any>;

  @Prop({ type: Boolean, default: () => false })
  public hideListonEdit: any;

  private activeItem = null;
  public currentPage = 1;
  public currentPerPage = 7;
  private pageChanged: any;
  private paginatedItems: any;

  protected onClickRow(item: any): void {
    this.activeItem = item;
    this.$emit("on-click-row", item);
  }

  protected isActive(item: any): boolean {
    return Object.is(item, this.activeItem);
  }

  protected getStudentCount(item: any): string {
    if (!item) return "";
    return `${item.studentCount}/${item.slots}`;
  }

  public paginateFunction(items: any, itemsPerPage: any, currentPage: any) {
    this.pageChanged = true;
  }

  @Watch("currentPage", { deep: true, immediate: true })
  public onCurrentPageChange(page: any) {
    const startIndex = (page - 1) * this.currentPerPage;
    this.paginatedItems = _(this.items).slice(startIndex).take(this.currentPerPage).value();
  }

  @Watch("items", { deep: true, immediate: true })
  public onItemsChange(items: any) {
    const startIndex = (this.currentPage - 1) * this.currentPerPage;
    this.paginatedItems = _(items).slice(startIndex).take(this.currentPerPage).value();
  }
}
