







































































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import FscMultiselect from "@/components/FscMultiselect.vue";
import FileButton from "@/components/Button/FileButton.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import UploadButton from "@/components/Button/UploadButton.vue";
import SendMailButton from "@/components/Button/SendMailButton.vue";
import { namespace } from "vuex-class";
import FscMultiselectScroll from "@/components/FscMultiselectScroll.vue";
import { Validations } from "vuelidate-property-decorators";
import Validation from "@/components/Validation.vue";
import { required } from "vuelidate/lib/validators";
import { formatStudentName } from "@/utils/NameUtil";

const MailboxModule = namespace("mailbox");
const StudentModule = namespace("student");
const RegionSpecificDocumentsModule = namespace("region-specific-documents");
const DocumentModule = namespace("document");
const TestingOrganizationsModule = namespace("testing-organizations");

@Component({
  components: {
    Validation,
    FscSimpleCard,
    FscMultiselect,
    FileButton,
    AbortButton,
    UploadButton,
    SendMailButton,
    FscMultiselectScroll,
  },
})
export default class TemplateEmailForm extends Vue {
  public name = "TemplateEmailForm";

  @DocumentModule.Action("findAll")
  public findAllMassEmailDocuments: any;

  @DocumentModule.Getter("getDataList")
  public allMassEmailDocuments: any;

  @RegionSpecificDocumentsModule.Action("findAll")
  public findAllRegionSpecificDocuments: any;

  @RegionSpecificDocumentsModule.Getter("getDataList")
  public allRegionSpecificDocuments: any;

  @MailboxModule.Action("getEmailTemplatesAllAction")
  private getEmailTemplatesAction: any;

  @MailboxModule.Action("sendEmailAction")
  private sendEmailAction: any;

  @MailboxModule.Getter("getSuccess")
  public emailSuccess: any;

  @MailboxModule.Getter("getIsLoading")
  public emailLoading: any;

  @MailboxModule.Action("fetchEmailsFilter")
  private fetchEmailsFilterAction: any;

  @MailboxModule.Getter("getEmailFilterNoMarked")
  private senderEmails: any;

  @MailboxModule.Getter("getSelectedEmailContent")
  private emailContent: any;

  /** TODO uncomment this section after email templates are available */
  @MailboxModule.Getter("getEmailTemplates")
  private emailTemplates: any;

  @TestingOrganizationsModule.Action("filter")
  public findAllTestingOrganizations: any;

  @TestingOrganizationsModule.Getter("getDataList")
  public testingOrganizations: any;

  @Prop()
  public allStudentsWithEmail!: any;

  @Prop()
  public allStudentsWithEmailLoading!: any;

  @Prop()
  public selectedStudents!: any;

  public customHeight = false;

  public formEmail: any = {
    sender: "",
    receiver: [],
    emailTemplate: null,
    documentTemplate: null,
    body: "",
    subject: "",
    uploadedFiles: [],
    repliedEmailId: 0,
    repliedAllEmailId: 0,
    forwardedEmailId: 0,
    regionSpecificDocumentIds: [],
    documentTypeIds: [],
  };

  @Validations()
  public validations() {
    return {
      formEmail: {
        body: { required },
        subject: { required },
        sender: { required },
      },
    };
  }

  public allDocuments: any = [];
  public selectedDocuments: any = [];

  public get filenames(): Array<string> {
    return this.formEmail.uploadedFiles?.map((file: any) => file.name) || [];
  }

  public get studentEmailsOptions(): Array<any> {
    let allOptions: any = [];
    if (this.allStudentsWithEmail && this.allStudentsWithEmail.length > 0) {
      this.allStudentsWithEmail?.map((student: any) => {
        const email = {
          name: formatStudentName(student.firstName, student.lastName),
          email: student.email,
        };
        allOptions.push(email);
      });
    }
    if (this.testingOrganizations && this.testingOrganizations.length > 0) {
      this.testingOrganizations.map((org: any) => {
        const email = {
          name: org.name ? org.name : "",
          email: org.email ? org.email : "",
        };
        allOptions.push(email);
      });
    }
    return allOptions;
  }

  protected get formHasError() {
    const requiredFields = ["subject", "body", "receiver"];
    return requiredFields.some((key) => (!this.formEmail[key] || this.formEmail?.receiver?.length === 0 ? true : false));
  }

  public mounted() {
    this.findAllMassEmailDocuments({ resource: "documents/documents-for-mass-mail" });
    this.findAllRegionSpecificDocuments({ resource: "region-specific-documents/driving-school/current" });
    this.getEmailTemplatesAction();
    this.fetchEmailsFilterAction();
    this.findAllTestingOrganizations({
      resource: "testing-organizations",
      filter: { showArchived: false },
    });
    this.$root.$on("form-reset", this.formReset);
  }

  public formReset() {
    this.formEmail = {
      sender: "",
      receiver: [],
      emailTemplate: null,
      documentTemplate: null,
      body: "",
      uploadedFiles: [],
      emailConfigId: 0,
      repliedEmailId: 0,
      repliedAllEmailId: 0,
      forwardedEmailId: 0,
      emailTemplateId: 0,
      regionSpecificDocumentIds: [],
      documentTypeIds: [],
    };
  }

  public onSelectEmailTemplate() {
    this.formEmail.subject = this.formEmail.emailTemplate.subject;
    this.formEmail.body = this.formEmail.emailTemplate.body;
    this.formEmail.emailTemplateId = this.formEmail.emailTemplate.id;
  }

  public onSetEmailBody(e: any) {
    this.formEmail.body = e;
  }

  public onSelectFile(e: any) {
    if (!e) return;
    let fileArray = Array.from(e.target.files);
    fileArray.map((file: any) => {
      this.formEmail.uploadedFiles.push(file);
    });
  }

  public removeSelectedFile(ind: number) {
    this.formEmail.uploadedFiles.splice(ind, 1);
  }

  public onEmailSend() {
    this.$v.$touch();
    if (this.$v.$invalid) {
      this.$toasted.error("Du hast keine Absendermailadresse eingetragen!");
      return;
    }

    const receiversArray = this.formEmail.receiver.map((receiver: any) => {
      if (receiver && receiver.email) {
        return receiver.email;
      }
      return receiver;
    });

    let regionSpD = [];
    let massEmD = [];

    if (this.selectedDocuments && this.selectedDocuments.length > 0) {
      regionSpD = this.selectedDocuments.filter((document: any) => document.regionSpecific === true).map((doc: any) => doc.id);
      massEmD = this.selectedDocuments.filter((document: any) => document.regionSpecific === false).map((doc: any) => doc.id);
    }

    const emails = receiversArray.join();

    if (emails) {
      const formData = new FormData();
      this.formEmail.uploadedFiles?.forEach((file: any) => {
        formData.append("files", file);
      });
      const data = {
        emailConfigId: this.formEmail.sender.id || 1,
        repliedEmailId: this.formEmail.repliedEmailId,
        repliedAllEmailId: this.formEmail.repliedAllEmailId,
        forwardedEmailId: this.formEmail.forwardedEmailId,
        to: this.formEmail.receiver ? emails : "",
        cc: "",
        subject: this.formEmail.subject || "(No subject)",
        body: this.formEmail.body,
        emailTemplateId: this.formEmail.emailTemplateId,
        regionSpecificDocumentIds: regionSpD,
        documentTypeIds: massEmD,
      };
      formData.append("email", JSON.stringify(data));
      this.sendEmailAction(formData);
      this.$emit("send-email");
      this.onAbort();
    } else {
      this.$toasted.error("Die eingegebene E-Mail Adresse muss ausgewählt oder mit ‘Enter’ bestätigt werden!");
    }
  }

  public onAbort() {
    this.formReset();
    this.$root.$emit("form-email-close");
  }

  @Watch("allMassEmailDocuments")
  public onMassEmailDocumentsChange(massEmailDocuments: any) {
    if (massEmailDocuments && massEmailDocuments.length > 0) {
      massEmailDocuments.map((document: any) => {
        document.regionSpecific = false;
        this.allDocuments.push(document);
      });
    }
  }

  @Watch("allRegionSpecificDocuments")
  public onAllRegionSpecificDocumentsChange(regionSpecificDocuments: any) {
    if (regionSpecificDocuments && regionSpecificDocuments.length > 0) {
      regionSpecificDocuments.map((document: any) => {
        document.regionSpecific = true;
        this.allDocuments.push(document);
      });
    }
  }

  @Watch("selectedStudents", { immediate: true, deep: true })
  public onSelectedStudentsChange(students: any) {
    if (students?.length > 0) {
      this.formEmail.receiver = students.map((student: any) => {
        return {
          name: `${student.firstName} ${student.lastName}`,
          email: student.email,
        };
      });
    }
  }

  @Watch("formEmail.receiver")
  public onReceiverChange() {
    if (this.formEmail.receiver.length > 15) {
      this.customHeight = true;
    } else {
      this.customHeight = false;
    }
  }
}
