import { Component, Vue } from "vue-property-decorator";
import moment from "moment";
import { BASIC_MATERIAL } from "@/constants/TheoryClasses";

@Component
export default class TheoryMixin extends Vue {
  protected getDate(item: any): string {
    if (!item) return "";
    if (!item.endDate) {
      return this.momentFormat(item.startDate);
    }
    return `${moment(item.startDate).format("DD.MM.")} - ${this.momentFormat(item.endDate)}`;
  }

  protected momentFormat(date: string): string {
    return moment(date).format("DD.MM.YYYY");
  }

  protected getIcon(item: any): string {
    if (item && item.theoryLessonId) return "calendar-day";
    return "calendar-days";
  }

  protected calcDuration(startTime: string, endTime: string): number {
    const start = moment.utc(startTime, "HH:mm");
    const end = moment.utc(endTime, "HH:mm");
    return moment.duration(end.diff(start)).as("minutes");
  }

  protected isBasicMaterial(item: any): boolean {
    return item.theoryClass?.id === BASIC_MATERIAL;
  }

  public get hasNotPermissionWrite(): boolean {
    return !this.$can(this.MODULE_THEORY_WRITE);
  }

  protected hasTheoryLessonGetActual(theoryLesson: any) {
    if (!theoryLesson) return false;
    const startTime = theoryLesson?.time || "00:00:00";
    return Date.parse(theoryLesson.date + "T" + startTime) - Date.now() < 0;
  }
}
