import { Component, Vue } from "vue-property-decorator";
import { AxiosError, AxiosResponse } from "axios";
import CrudService from "@/services/CrudService";

const crudService = new CrudService();
const URL = "/students/student-list";

@Component
export default class StudentListRequestMixin extends Vue {
  protected studentList: Array<any> = [];
  protected studentListTotal = 0;
  protected studentListSuccess = false;
  protected studentListError: Record<any, any> | null = null;
  protected studentListLoading = false;

  public async fetchStudentList(filter = {}): Promise<void> {
    this.studentListLoading = true;
    this.studentListSuccess = false;
    this.studentListError = null;
    return await crudService
      .filter(
        {
          resource: `${URL}`,
          filter,
        },
        ""
      )
      .then((response: AxiosResponse) => {
        this.studentList = response.data.data;
        this.studentListTotal = response.data.total;
      })
      .catch((error: AxiosError) => {
        this.studentListError = error.response?.data;
      })
      .finally(() => {
        this.studentListLoading = false;
      });
  }
}
