



































import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import TheoryMixin from "@/mixins/TheoryMixin";

@Component
export default class TheoryDetails extends mixins(TheoryMixin) {
  public name = "TheoryDetails";

  @Prop()
  protected selectedTheory!: any;

  @Prop()
  protected theory!: any;

  protected get durationStart(): string {
    if (!this.theory) return "";
    return this.theory.time || `${this.theory.timeFrame[0].startTime}`;
  }

  protected get durationEnd(): string {
    if (!this.theory) return "";
    return this.theory.endTime || `${this.theory.timeFrame[0].endTime}`;
  }

  protected get branch(): string {
    if (!this.theory) return "";
    const { branch } = this.theory;
    return `${branch.postalCode} - ${branch.location}`;
  }

  protected get theoryClass(): string {
    if (!this.theory) return "";
    const { theoryClasses, theoryClass } = this.theory;
    return theoryClasses ? theoryClasses.map((x: any) => x.name).join(", ") : theoryClass.name;
  }
}
